import React from 'react'
import './header.styl'
import {Navbar, Nav, Container} from "react-bootstrap"
import Logo from '../../images/logo.png'
import Image from "react-bootstrap/Image";
import { withRouter } from 'react-router-dom'

class Header extends React.Component{
  // constructor (props) {
  //   super(props)
  // }
  render() {
    let url = this.props.location.hash
    // if (url.include())
    console.log(url)
    return (
      <Navbar collapseOnSelect fixed="top" expand="lg" className="head-dv">
        <Container>
          <Navbar.Brand href="#home" className="logo-text">
            <img src={Logo} alt="Logo"/>&nbsp;&nbsp;信确CRM
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link className={url.includes('home') ? 'active' : ''} href={'#home'}>首页</Nav.Link>
              <Nav.Link className={url.includes('crm') ? 'active' : ''} href={'#crm'}>信确CRM</Nav.Link>
              <Nav.Link className={url.includes('download') ? 'active' : ''} href={'#download'}>CRM下载</Nav.Link>
              <Nav.Link className={url.includes('about') ? 'active' : ''} href={'#about'}>关于我们</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end">
            <Image height={40} width={158} src={require('../../images/iscTag.png')} alt={'isv'}/>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }
}

export default withRouter(Header)
