import React from 'react';
import './mainFunction.styl'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";

class MainFunction extends React.Component{
  constructor () {
    super()
    this.state = {
      activeKey: 'serve',
      detail: [
        {
          key: 'serve',
          content: {
            text: [
              '客户资料分类精细管理',
              '跟进记录强提醒，外勤可定位打卡与拍照',
              '群发短信，同时联系多个客户'
            ],
            imgs: [
              {url: require('../../images/fun-imgs/customer.png'), title: 'PC', top: '20', right: '0'},
            ]
          }
        },
        {
          key: 'product',
          content: {
            text: [
              '分类管理产品数据，清晰有序',
              '产品数据实时统计，直观明了'
            ],
            imgs: [
              {url: require('../../images/fun-imgs/product.png'), title: 'PC', top: '0', right: '150'}
            ]
          }
        },
        {
          key: 'seaCustom',
          content: {
            text: [
              '资源共享，提高资源利用率',
              '支持多公海设置，每个公海对应不同规则',
              '公海池全方位隐私设置，避免挑拣客户资料'
            ],
            imgs: [
              {url: require('../../images/fun-imgs/seaCustom.png'), title: 'PC', top: '0', right: '0'}
            ]
          }
        },
        {
          key: 'order',
          content: {
            text: [
              '自定义合同字段，按需设定',
              '回款单智能关联，清晰直观'
            ],
            imgs: [
              {url: require('../../images/fun-imgs/order.png'), title: 'PC', top: '0', right: '240'}
            ]
          }
        },
        {
          key: 'import',
          content: {
            text: [
              '支持模板导入或自定义导入',
              '支持一次性最多导入30000条资料',
              '客户资料数量无限制'
            ],
            imgs: [
              {url: require('../../images/fun-imgs/import.png'), title: 'PC', top: '0', right: '-80'}
            ]
          }
        },
        {
          key: 'oa',
          content: {
            text: [
              '自定义审批流程，高效管理',
              '自定义工作报告，统一直观'
            ],
            imgs: [
              {url: require('../../images/fun-imgs/oa.png'), title: 'PC', top: '0', right: '0'}
            ]
          }
        }
      ],
      currentDetail: {
        text: [
          '客户资料分类精细管理',
          '跟进记录强提醒，外勤可定位打卡与拍照',
          '群发短信，同时联系多个客户'
        ],
        imgs: [
          {url: require('../../images/fun-imgs/customer.png'), title: 'PC', top: '0', right: '0'}
        ]
      }
    }
  }
  // 导航条切换
  changeNav = (eventKey) => {
    // console.log(eventKey, event)
    this.setState({activeKey: eventKey})
    this.state.detail.forEach((item) => {
      if (eventKey === item.key) {
        this.setState({currentDetail: item.content})
      }
    })
  }
  render() {
    let handleSelect = (eventKey) => this.changeNav(eventKey)
    let delCon = this.state.currentDetail
    return(
      <div className={'main-fun-dv'}>
        <div className={'title'}>信确CRM核心功能</div>
          <Container>
            <div className={'con-dv'}>
              <Nav className={'justify-content-center'} activeKey={this.state.activeKey} onSelect={handleSelect}>
                <Nav.Item>
                  <Nav.Link eventKey="serve">客户服务</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="product">产品管理</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="seaCustom">公海池管理</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="order">合同订单</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="import">资料导入</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="oa">协同办公</Nav.Link>
                </Nav.Item>
              </Nav>
              <div className={'detail-dv'}>
                <div className={'left-dv'}>
                  <ul>
                    {delCon.text.map((item, index) => {
                      return(<li key={index}><span></span><div>{item}</div></li>)
                    })}
                  </ul>
                  <div className={'btn-dv'}><Button href={'#crm'} className="btn">了解详情</Button></div>
                </div>
                <div className={'right-dv'}>
                  <div className={'img-dv'}>
                    {delCon.imgs.map((item,index) => {
                      return(
                          <Image fluid key={index} src={item.url} alt={item.title}/>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Container>
      </div>
    )
  }
}

export default MainFunction
