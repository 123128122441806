import React from "react"
import './partner.styl'
import {Container} from "react-bootstrap";

class Partner extends React.Component{
  constructor() {
    super()
    this.state = {
      imgs: [
        {url: require('../../images/partner-img/01.png'), title: '中国电信'},
        {url: require('../../images/partner-img/02.png'), title: '一加手机'},
        {url: require('../../images/partner-img/03.png'), title: '文都教育'},
        {url: require('../../images/partner-img/04.png'), title: '舜宝科技'},
        {url: require('../../images/partner-img/05.png'), title: '广州崇泽'},
        {url: require('../../images/partner-img/06.png'), title: '山东镭泽'},
        {url: require('../../images/partner-img/07.png'), title: '合付宝'},
        {url: require('../../images/partner-img/08.png'), title: '贵州中闽教育'},
      ]
    }
  }
  render() {
    let imgList = this.state.imgs
    return(
      <div className={'partner-dv'}>
        <div className={'title'}>合作伙伴</div>
        <Container>
          <div className={'img-li'}>
            <ul>
              {imgList.map((item, index) => {
                return(
                  <li key={index}>
                    <img src={item.url} alt={item.title}/>
                  </li>
                )
              })}
            </ul>
          </div>
        </Container>
      </div>
    )
  }
}

export default Partner
