import React from 'react'
import './index.styl'
import {Button, Container, Image} from 'react-bootstrap'

class Crm extends React.Component{
  render() {
    return(
      <div className={'crm-dv'}>
        <Container>
          <div className={'banner-dv'}>
            <div className={'right-dv'}>
              <Image fluid src={require('../../images/crm-imgs/banner-img.png')} alt={'crm Banner'}/>
            </div>
            <div className={'left-dv'}>
              <h1>信确CRM，匠心打造产品</h1>
              <h4>以提高销售额为目的，全行业通用设置</h4>
              <div className={'btn-dv'}>
                <Button href={'#downLoad'}>立即下载</Button>
              </div>
            </div>
          </div>
        </Container>
        <div className={'product-dv'}>
          <div className={'title'}>信确CRM产品介绍</div>
          <Container>
            <div className={'content'}>
              <div className={'left-dv'}>
                <div className={'menu'}>
                  <div className={'num-li'}>01</div>
                  <h4>客户关系管理</h4>
                </div>
                <div className={'text'}>
                  <p><span>1、一键导入</span>：支持一次导入3万条数据；导入字段自定义</p>
                  <p><span>2、判重查重</span>：系统自动进行字段判重，重复录入；支持自定义筛选查重，防止撞单</p>
                  <p><span>3、遗忘提醒</span>：自动推送消息，避免客户流失</p>
                  <p><span>4、跟进管理</span>：外勤拍照打卡；批量设置跟进提醒，准时推送</p>
                </div>
              </div>
              <div className={'right-dv'}>
                <div className={'img-dv'}>
                  <Image fluid src={require('../../images/fun-imgs/customer.png')} alt={'客户关系管理1'}/>
                </div>
                {/*<img className={'f-right'} src={require('../../images/crm-imgs/product1.png')} alt={'bg'}/>*/}
              </div>
            </div>
            <div className={'content-2'}>
              <div className={'left-dv'}>
                <div className={'img-dv'}>
                  <Image fluid src={require('../../images/fun-imgs/product.png')} alt={'产品1'}/>
                </div>
              </div>
              <div className={'right-dv'}>
                <div>
                  <div className={'menu'}>
                    <div className={'num-li'}>02</div>
                    <h4>产品管理</h4>
                  </div>
                  <div className={'text'}>
                    <p><span>1、多规格导入</span>：支持产品多规格导入，提高效率</p>
                    <p><span>2、库存管理</span>：自动化库存管理，降低人力成本</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={'content-3'}>
              <div className={'left-dv'}>
                <div className={'menu'}>
                  <div className={'num-li'}>03</div>
                  <h4>订单管理</h4>
                </div>
                <div className={'text'}>
                  <p><span>1、回款管理</span>：订单回款智能关联，订单进度一目了然</p>
                  <p><span>2、合同订单</span>：自定义推送消息条件，实时掌握订单状态</p>
                </div>
              </div>
              <div className={'right-dv'}>
                <div className={'img-dv'}>
                  <Image fluid src={require('../../images/fun-imgs/order.png')} alt={'order2'}/>
                </div>
              </div>
            </div>
            <div className={'content-4'}>
              <div className={'right-dv'}>
                <div className={'img-dv'}>
                  <Image fluid src={require('../../images/crm-imgs/ooaa.png')} alt={'oa1'}/>
                </div>
              </div>
              <div className={'left-dv'}>
                <div className={'menu'}>
                  <div className={'num-li'}>04</div>
                  <h4>协同办公</h4>
                </div>
                <div className={'text'}>
                  <p><span>1、审批流程自定义</span>：自定义审批规则，满足条件自动触发</p>
                  <p><span>2、工作报告</span>：员工按时间汇报业绩数据，总结经验，制定计划，提高工作效益</p>
                </div>
              </div>
            </div>
            <div className={'content-5'}>
              <div className={'left-dv'}>
                <div className={'menu'}>
                  <div className={'num-li'}>05</div>
                  <h4>特色功能</h4>
                </div>
                <div className={'text'}>
                  <p><span>1、群发短信</span>：制定公司专属签名、添加公司短信模板，一键发送，增进与客户之间的交流，提升成单率。</p>
                  <p><span>2、定制开发</span>：支持表单自定义，根据公司业务需求，设计系统表单，最大程度满足需求</p>
                  <p><span>3、多公海池</span>：多个公海池随需设置，资源共享，提高资源利用率</p>
                </div>
              </div>
              <div className={'right-dv'}>
                <div className={'img-dv'}>
                  <Image fluid src={require('../../images/fun-imgs/seaCustom.png')} alt={'公海池'}/>
                  <Image fluid src={require('../../images/crm-imgs/product4.png')} alt={'抢'}/>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className={'case-dv'}>
          <div className={'title'}>客户案例</div>
          <Container>
            <div className={'con-dv'}>
              <div className={'con-li'}>
                <div className={'img-dv'}>
                  <img src={require('../../images/case-imgs/case2.png')} alt={'案例1'}/>
                </div>
                <div className={'text-dv'}>
                  <h4>电子科技行业 | 深圳市舜宝科技有限公司</h4>
                  <div className={'tt-dv'}>深圳市舜宝科技有限公司是一家低温无燃烧电子烟技术研发商。 集电子产品的研发、生产、制造、销售为一体，主要销售众多种类的电子烟。</div>
                  <p><span>公司痛点：</span>客户来源广，数据统计难；销售资料难保管</p>
                  <p><span>信确CRM帮助功能点：</span>客户管理；数据中心；合同订单</p>
                </div>
              </div>
              <div className={'con-li'}>
                <div className={'text-dv'}>
                  <h4>家居装修行业 | 江苏碳元绿色建筑科技有限公司</h4>
                  <div className={'tt-dv'}>碳元绿色建筑科技有限公司是一家专注建筑环境领域产品和服务的高新技术企业。致力于发挥碳材料特性，提供高效节能、舒适健康的室内气候环境系统解决方案。</div>
                  <p><span>公司痛点：</span>销售不熟悉产品库存；老板无法随时查看员工状态和销售进度</p>
                  <p><span>信确CRM帮助功能点：</span>产品管理；多端协同办公</p>
                </div>
                <div className={'img-dv'}>
                  <img src={require('../../images/case-imgs/case3.jpg')} alt={'案例2'}/>
                </div>
              </div>
              <div className={'con-li'}>
                <div className={'img-dv'}>
                  <img src={require('../../images/case-imgs/case5.jpg')} alt={'案例3'}/>
                </div>
                <div className={'text-dv'}>
                  <h4>教育行业 | 福建省道达教育咨询有限公司</h4>
                  <div className={'tt-dv'}>公司专注于“国学+拓展”的体验教育课程，尊奉东方传统文化为道，结合西方拓展训练为术，以道驭术，打造知行合一的团队、内圣外王的伙伴！</div>
                  <p><span>公司痛点：</span>人员多，难管理；无法及时得知教学进度</p>
                  <p><span>信确CRM帮助功能点：</span>短信群发；数据统计；工作报告</p>
                </div>
              </div>
              <div className={'con-li'}>
                <div className={'text-dv'}>
                  <h4>汽车行业 | 深圳市前海捷车会实业控股有限公司</h4>
                  <div className={'tt-dv'}>一般经营项目是：汽车零配件的研发及销售;二手车销售； 新车销售； 汽车信息咨询、企业管理咨询、投资咨询(不含限制项目);展览展示策划;投资兴办实业</div>
                  <p><span>公司痛点：</span>车型和购买方式多元化，产品信息复杂</p>
                  <p><span>信确CRM帮助功能点：</span>产品管理；数据导入</p>
                </div>
                <div className={'img-dv'}>
                  <img src={require('../../images/case-imgs/case6.jpeg')} alt={'案例4'}/>
                </div>
              </div>
              <div className={'con-li'}>
                <div className={'img-dv'}>
                  <img src={require('../../images/case-imgs/case4.jpg')} alt={'案例5'}/>
                </div>
                <div className={'text-dv'}>
                  <h4>旅游服务行业 | 小蜥亦（南京）微定制旅行</h4>
                  <div className={'tt-dv'}>小蜥亦是湖南境外旅游旗下子公司，是经营出境旅游、入境旅游、国内旅游的专业机构。公司提供全球各地旅游咨询、路线规划、导游服务等。</div>
                  <p><span>公司痛点：</span>员工在外行为不可控；客户来源复杂</p>
                  <p><span>信确CRM帮助功能点：</span>外勤打卡；工作报告；客户资料精细化管理</p>
                </div>
              </div>
              <div className={'con-li'}>
                <div className={'text-dv'}>
                  <h4>房地产行业 | 湖南善德房地产经纪有限公司</h4>
                  <div className={'tt-dv'}>湖南善德房地产经纪有限公司始建于2019年，位于湖南长沙。善德一直坚持"广招慎选，慎选我爱，深爱我选"的用人理念，始终给员工营造一种家的温馨感觉。</div>
                  <p><span>公司痛点：</span>客户与房源信息多且杂；销售周期长；客户资源易重复或浪费</p>
                  <p><span>信确CRM帮助功能点：</span>客户分类管理；跟进提醒；公海池系统</p>
                </div>
                <div className={'img-dv'}>
                  <img src={require('../../images/case-imgs/case7.jpeg')} alt={'案例6'}/>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    )
  }
}

export default Crm
