import React from 'react'
import './index.styl'
import Slide from '../../components/slide'
import FunctionList from './functionList'
import MainFunction from './mainFunction'
import CaseList from './caseList'
import Partner from './partner'

class Index extends React.Component {
  render () {
    return (
      <div className={'index-con'}>
        {/* 轮播图*/}
        <Slide/>
        {/* 销勤勤能做什么？ */}
        <FunctionList/>
        {/* 销勤勤核心功能 */}
        <MainFunction/>
        {/* 客户案例 */}
        <CaseList/>
        {/* 合作伙伴 */}
        <Partner/>
      </div>
    )
  }
}

export default Index
