import React from "react"
import './slide.styl'
import Carousel from 'react-bootstrap/Carousel'
import Button from 'react-bootstrap/Button'
import {Container} from "react-bootstrap";

class Slide extends React.Component{
  render(){
    return (
      <Carousel className="slide-dv">
        <Carousel.Item className="contain">
          <img className="slide-img" src={require('../images/slide1.png')} alt='img-1'/>
          <Carousel.Caption>
            <div>
              <h1>以增加销售额为目的，全行业通用</h1>
              <div className="text">
                根据需求自由设计字段<br/>
                一体化企业管理，满足企业各角色需要
              </div>
              <Button href={'#download'} variant="success">立即了解</Button>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="contain">
          <img className="slide-img" src={require('../images/slide2.png')} alt='img-1'/>
          <Carousel.Caption>
            <Container>
              <div className="banner-con">
                <div className={'text-dv'}>
                  <h1>高效管理与客户的关系</h1>
                  <div className="text">
                    让客户管理更高效智能<br/>
                    挖掘潜藏客户，带来更多机会<br/>
                    遗忘提醒，不错过每一个客户
                  </div>
                  <Button href={'#download'} className="btn">立即了解</Button>
                </div>
                <div className={'img-dv'}>
                  <img src={require('../images/banner-img.png')} alt='banner-img'/>
                </div>
              </div>
            </Container>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    )
  }
}
export default Slide;
