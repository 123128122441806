import React from 'react'
import './index.styl'
import {Container, Collapse, Image} from "react-bootstrap";

class About extends React.Component{
  constructor() {
    super()
    this.state = {
      open1: false,
      open2: false,
      open3: false,
      open4: false
    }
  }
  render() {
    return(
      <div className={'about-dv'}>
        <div className={'banner-dv'}>
          <Container>
            <div className={'text'}>
              匠心做产品&nbsp;&nbsp;热心做服务
            </div>
          </Container>
        </div>
        <Container>
          <div className={'con-dv'}>
            <div className={'menu'}>
              <h1>01</h1>
              <p>公司简介</p>
            </div>
            <div className={'introduce-dv'}>
              <div className={'border-dv'}></div>
              <div className={'img-dv'}><Image fluid src={require('../../images/about-imgs/company.png')} alt='公司简介'/></div>
              <div className={'text'}>
                <div className={'son-title'}>公司简介</div>
                <p>湖南信确电子商务科技有限公司成立于2018年，坐落在风景优美的长沙岳麓区。 自成立以来，
                湖南信确电子商务科技有限 公司顺应国家“互联网+”战略，对中小微企业管理创新抱有强烈的使命感，致
                力于为中小微企业提供易用、灵活、安全的业务全流程管理系统。 公司现已对多家用户提供了专业的CRM系
                统服务，覆盖20多个行业，并深受用户好评。</p>
              </div>
            </div>
            <div className={'menu'}>
              <h1>02</h1>
              <p>信确CRM</p>
            </div>
            <div className={'xqcrm-dv'}>
              <div className={'border-dv'}></div>
              <div className={'text'}>
                <div className={'son-title'}>信确CRM</div>
                <p>湖南信确电子商务科技有限公司旗下运营的核心产品是信确CRM，以移动管理为核心，通过运用移动互联网、云计
                算、大数据、社交等先进互联网技术， 全面围绕客户的核心需求，高效解决中小企业的 “营销-销售-订单-采购-库
                存-售后”的全流程客户服务难题，全面满足企业高效协同办公、客户资料 精细管理、 销售行为严谨管理、销售过程
                精准管控、决策数据可视直观等需求， 帮助企业实现信息科学化管理，快速提升企业运营效率并实现销售业绩长期增长！</p>
              </div>
              <div className={'img-dv'}><img src={require('../../images/about-imgs/crm.png')} alt='信确crm'/></div>
            </div>
            <div className={'menu'}>
              <h1>03</h1>
              <p>企业初衷</p>
            </div>
            <div className={'company-dv'}>
              <div className={'border-dv'}></div>
              <div className={'img-li'}>
                <div>
                  <img src={require('../../images/about-imgs/overturn.png')} alt={'颠覆'}/>
                  <p>颠覆</p>
                </div>
                <div>
                  <img src={require('../../images/about-imgs/create.png')} alt={'创新'}/>
                  <p>创新</p>
                </div>
                <div>
                  <img src={require('../../images/about-imgs/develop.png')} alt={'发展'}/>
                  <p>发展</p>
                </div>
              </div>
            </div>
            <div className={'menu'}>
              <h1>04</h1>
              <p>常见问题</p>
            </div>
            <div className={'problem-dv'}>
              <div className={'border-dv'}></div>
              <div className={'text'}>
                <div className={'pro-dv'} aria-controls="example-collapse-text" onClick={() => this.setState({open1: !this.state.open1})}>1、试用30天是不是真的？</div>
                <div>
                  <Collapse className={'answer-dv'} in={this.state.open1}>
                    <div id="example-collapse-text">
                      对的，我们信确CRM有免费的30天试用时间，供用户熟悉、了解我们的软件
                    </div>
                  </Collapse>
                </div>
                <div className={'pro-dv'} aria-controls="example-collapse-text" onClick={() => this.setState({open2: !this.state.open2})}>2、要是我不会使用你们软件怎么办？</div>
                <div>
                  <Collapse className={'answer-dv'} in={this.state.open2}>
                    <div id="example-collapse-text">
                      我们有专业的客户进行在线专属指导，对于用户的任何疑问我们都会进行详解答，保证每一个客户都能学会使用我们的软件。
                    </div>
                  </Collapse>
                </div>
                <div className={'pro-dv'} aria-controls="example-collapse-text" onClick={() => this.setState({open3: !this.state.open3})}>3、如果我有自己的需求，你们可以满足我的要求吗？</div>
                <div>
                  <Collapse className={'answer-dv'} in={this.state.open3}>
                    <div id="example-collapse-text">
                      对于这一点，信确CRM欢迎广大客户对我们软件提出各种相应的需求，可以让我们不断完善系统，为用户带来更好的体验。
                    </div>
                  </Collapse>
                </div>
                <div className={'pro-dv'} aria-controls="example-collapse-text" onClick={() => this.setState({open4: !this.state.open4})}>4、你们软件会持续开发新功能吗？</div>
                <div>
                  <Collapse className={'answer-dv'} in={this.state.open4}>
                    <div id="example-collapse-text">
                      对于这一点，信确CRM欢迎广大客户对我们软件提出各种相应的需求，可以让我们不断完善系统，为用户带来更好的体验。
                    </div>
                  </Collapse>
                </div>
              </div>
              <div className={'img-dv'}><img src={require('../../images/about-imgs/ding-contact.png')} alt={'钉钉客服'}/></div>
            </div>
          </div>
        </Container>
      </div>
    )
  }
}

export default About
