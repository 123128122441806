import React from "react"
import './functionList.styl'
import {Container} from "react-bootstrap"

class FunctionList extends React.Component{
  constructor () {
    super();
    this.state = {
      imgs: [
        { url: require('../../images/fun-imgs/fast-response.png'), title: 'fast-response', content: '智能高效 快速响应'},
        { url: require('../../images/fun-imgs/customize.png'), title: 'customize', content: '按需定制 随需而变'},
        { url: require('../../images/fun-imgs/interface-simple.png'), title: 'interface-simple', content: '界面精简 易于上手'},
        { url: require('../../images/fun-imgs/safe.png'), title: 'safe', content: '数据保密 安全可靠'},
        { url: require('../../images/fun-imgs/function-rich.png'), title: 'function-rich', content: '系统强大 功能丰富'},
        { url: require('../../images/fun-imgs/multi-synergy.png'), title: 'multi-synergy', content: '多端协同 数据同步'}
      ]
    }
  }
  render() {
    let dataList = this.state.imgs
    return (
      <div className={'functino-dv'}>
        <div className={'title'}>信确CRM能做什么？</div>
        <div className={'function-li'}>
          <Container>
            <div className={'ul-dv'}>
              {dataList.map((item, index) => {
                return (
                  <div className={'li-dv'} key={index}>
                    <img src={item.url} alt={item.title}/>
                    <div>{item.content}</div>
                  </div>
                )
              })}
            </div>
          </Container>
        </div>
      </div>
    )
  }
}
export default FunctionList
