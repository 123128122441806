import React from 'react'
import './index.styl'
import {Container, Image} from 'react-bootstrap'

class CrmDownLoad extends React.Component{
  render() {
    return(
      <div className={'downLoad-dv'}>
        <Container>
          <div className={'con-dv'}>
            <div className={'left-dv'}>'
              <Image fluid src={require('../../images/downLoad.png')} alt='下载配图'/>
            </div>
            <div className={'right-dv'}>
              <div className={'text'}>
                <h1>信确CRM</h1>
                <h1>高效管理客户关系</h1>
              </div>
              <div className={'img-dv'}>
                <Image fluid src={require('../../images/2wema.png')} alt='二维码'/>
              </div>
              <div className={'step-dv'}>
                <Image fluid src={require('../../images/step.png')} alt='步骤条'/>
                <div>
                  <span>1.用钉钉扫描上方二维码</span>
                  <span>2.选择组织/公司</span>
                  <span>3.试用/订购信确CRM</span>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    )
  }
}

export default CrmDownLoad
