import React from 'react'
import './footer.styl'
import {Button, Container} from 'react-bootstrap'

class Footer extends React.Component{
  render() {
    return(
      <div className={'foot-dv'}>
        <Container>
          <div className={'con-dv'}>
            <div className={'content'}>
              <div className={'con'}>
                <h5>关于产品</h5>
                <div><Button variant="link" className={'new-btn'} href={'#crm'}>产品功能</Button></div>
                <div><Button variant="link" className={'new-btn'}  href={'#crm'}>案例介绍</Button></div>
              </div>
              <div className={'con'}>
                <h5>联系我们</h5>
                <div><span className={'contactImg'}><img src={require('../../images/Email.png')} alt='邮箱'/></span>eor9078@dingtalk.com</div>
                <div><span className={'contactImg conImg'}><img src={require('../../images/address.png')} alt='地址'/></span>长沙市岳麓区科技金融大厦501</div>
              </div>
              <div className={'con'}>
                <img className={'ding-img'} src={require('../../images/ding.png')} alt='钉钉二维码'/>
                <p>钉钉咨询</p>
              </div>
            </div>
          </div>
          <div className={'copyright-dv'}><a href={'https://beian.miit.gov.cn'}>湘ICP备19026163号-1</a></div>
        </Container>
      </div>
    )
  }
}

export default Footer
