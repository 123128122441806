import React from "react"
import './caseList.styl'
import Card from 'react-bootstrap/Card'
import CardDeck from "reactstrap/es/CardDeck"
import {Button, Container} from "react-bootstrap"

class CaseList extends React.Component{
  constructor (){
    super()
    this.state = {
      cardData: [
        {
          img: {url: require('../../images/case-imgs/case1.jpg'), title: '装修'},
          title: '家居装修行业 | 江苏碳元绿色建筑科技有限公司',
          content: '江苏碳元绿色建筑科技有限公司是碳元科技（上证A股603133）下属全资子公司，公司成立于2015年7月，坐落于风景优美的常州武进西太湖产业园区，是一家专注建...'
        },
        {
          img: {url: require('../../images/case-imgs/case2.png'), title: '科技'},
          title: '电子科技行业 | 深圳市舜宝科技有限公司',
          content: '深圳市舜宝科技有限公司于2017年7月成立于深圳蛇口网谷，是一家专注于低温加热不燃烧产品技术研发的互联网' +
            '科技公司。通过独有的新一代HNB核心技术...'
        },
        {
          img: {url: require('../../images/case-imgs/case3.jpg'), title: '贸易'},
          title: '金融贸易行业 | 山西点滴财务咨询有限公司',
          content: '山西点滴财务咨询有限公司是北京财务公司在太原成立的一家分公司，从企业环节入手，解决企业在“生老病死”过程中的各种刚性服务需求。专业为企业提供商务...'
        }
      ]
    }
  }
  render() {
    let cardList = this.state.cardData
    return(
      <div className={'case-dv'}>
        <div className={'title'}>客户案例</div>
        <Container>
          <CardDeck>
            {cardList.map((item, index) => {
              return(
                <Card key={index}>
                  <Card.Img variant="top" src={item.img.url} />
                  <Card.Body>
                    <Card.Title>{item.title}</Card.Title>
                    <Card.Text>
                      {item.content}
                    </Card.Text>
                  </Card.Body>
                </Card>
              )
            })}
          </CardDeck>
          <div className={'btn-dv'}><Button href={'#crm'}>查看更多</Button></div>
        </Container>
      </div>
    )
  }
}

export default CaseList
