import React from 'react';
import { HashRouter as Router, Route, Redirect, withRouter } from 'react-router-dom';
import './App.styl';
import Home from './pages/index/index.js'
import CrmDownLoad from './pages/crmDownload/index.js'
import About from './pages/about'
import Crm from './pages/crm'
// import createHistory from 'history/createHashHistory'

const history = require("history").createHashHistory

class App extends React.Component{
  render() {
    return(
      <Router history={history}>
        <Route render={({ location }) => {
          return(
            <div className={'App'}>
              <Route location={location} exact path='/' component={Home}/>
              <Route location={location} path='/home' component={Home}/>
              <Route location={location} path='/crm' component={Crm}/>
              <Route location={location} path='/download' component={CrmDownLoad}/>
              <Route location={location} path='/about' component={About}/>
              <Redirect from={'/'} to={'/home'} />
              {/*<Route location={location} path="/comment/:id" component={Comment} />*/}
            </div>
          )}}/>
      </Router>
    )
  }
}

export default withRouter(App);
